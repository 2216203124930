import type { RouteComponentProps } from "@reach/router";

import { SessionLoader } from "../session";
import { assertDefined } from "../utils";
import { ForgetPasswordPageContents } from "./ForgetPasswordPageContents";

export type ForgotPasswordPageProps = Readonly<RouteComponentProps>;

export function ForgotPasswordPage({ location }: RouteComponentProps) {
  assertDefined(location);

  return (
    <SessionLoader audience="unauthenticated" location={location}>
      <ForgetPasswordPageContents />
    </SessionLoader>
  );
}
