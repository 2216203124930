import { css } from "@emotion/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { message } from "antd";
import { navigate } from "gatsby";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { object, string } from "yup";

import { useAddAlert } from "../alert";
import { analyticsEnqueueEvent } from "../analytics";
import PadlockIcon from "../assets/images/Padlock.png";
import { LayoutDashboard } from "../components";
import AppButton from "../components/BaseComponents/Button";
import ErrorMessage from "../components/BaseComponents/ErrorMessage";
import AppInput from "../components/BaseComponents/Input";
import { sessionSendPasswordResetEmail } from "../session";
import { useAppDispatch } from "../store";
import { handleError } from "../utils/handleError";
import validation from "../utils/validation";
import { PasswordRequirements } from "./PasswordRequirements";

interface ForgetPasswordFormType {
  email: string;
  password: string;
}

export function ForgetPasswordPageContents() {
  const dispatch = useAppDispatch();
  const [hasSentResetPasswordEmail, setEmailStatus] = useState<boolean>(false);
  const validationSchema = useRef(
    object().shape({
      email: string()
        .required(validation.email.required)
        .email(validation.email.invalid),
    }),
  );
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ForgetPasswordFormType>({
    resolver: yupResolver(validationSchema.current),
    mode: "onChange",
  });
  const addAlert = useAddAlert();

  const onSubmit: Parameters<typeof handleSubmit>[0] = async (data) => {
    try {
      await dispatch(
        sessionSendPasswordResetEmail({ email: data.email }),
      ).unwrap();
      message.success(
        "You have successfully requested a new password reset! Please check your email.",
      );
      setEmailStatus(true);

      dispatch(
        analyticsEnqueueEvent({
          eventName: "passwordResetRequested",
        }),
      );
    } catch (error) {
      handleError(addAlert, error);
    }
  };

  return (
    <LayoutDashboard page="forgot-password">
      <div
        css={css`
          padding-bottom: 61px;
        `}
      >
        <h3
          className="text-4xl text-snap-black font-800"
          css={css`
            margin-bottom: 6px;
            line-height: 44px;
          `}
        >
          Forgot Password
        </h3>
        <hr
          className="border-t-black"
          css={css`
            margin-bottom: 40px;
          `}
        />

        <PasswordRequirements className="mb-8" />

        {hasSentResetPasswordEmail ? (
          <div className="flex flex-col">
            <img
              src={PadlockIcon}
              alt=""
              className="self-center w-24 h-24"
              css={css`
                margin-bottom: 34px;
              `}
            />
            <h3
              className="tracking-0.2 font-700 text-snap-ebony-clay"
              css={css`
                line-height: 32px;
                font-size: 19px;
                margin-bottom: 9px;
              `}
            >
              We sent you an email to reset your Password
            </h3>
            <p
              className="text-snap-ebony-clay"
              css={css`
                line-height: 32px;
                font-size: 19px;
              `}
            >
              {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sit
              facilisi diam pretium, suspendisse vitae maecenas at. Convallis
              amet suspendisse hendrerit eu massa semper leo, venenatis. */}
            </p>
            <div className="flex gap-8 mt-8">
              <AppButton
                className="justify-self-start bg-white !text-snap-blue font-mulish"
                css={css`
                  width: 271px;
                  font-size: 17px;
                `}
                onClick={() => setEmailStatus(false)}
              >
                BACK
              </AppButton>
              {/* <AppButton
                className="justify-self-end font-mulish"
                css={css`
                  width: 271px;
                  font-size: 17px;
                `}
                htmlType="submit"
              >
                CONTINUE
              </AppButton> */}
            </div>
          </div>
        ) : (
          <form
            action=""
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col"
          >
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div
                  css={css`
                    height: 97px;
                  `}
                >
                  <AppInput
                    name="email"
                    label="Type your email address"
                    placeholder="address@email.com"
                    className="w-330 max-w-full"
                    onChange={onChange}
                    value={value}
                  />
                  <ErrorMessage
                    className="text-red xs:mb-2"
                    css={css`
                      margin-bottom: 10px;
                    `}
                  >
                    {errors?.email?.message}
                  </ErrorMessage>
                </div>
              )}
            />
            <div className="flex gap-8 mt-8">
              <AppButton
                className="justify-self-start bg-white !text-snap-blue font-mulish"
                css={css`
                  width: 271px;
                  font-size: 17px;
                `}
                onClick={() => navigate(-1)}
              >
                BACK
              </AppButton>
              <AppButton
                className="justify-self-end font-mulish"
                css={css`
                  width: 271px;
                  font-size: 17px;
                `}
                htmlType="submit"
              >
                CONTINUE
              </AppButton>
            </div>
          </form>
        )}
      </div>
    </LayoutDashboard>
  );
}
